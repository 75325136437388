import React from 'react'
import Layout from '../components/layout'
import { graphql, Link } from "gatsby"
import TwitterIcon from '../components/twitterIcon'
import FacebookIcon from '../components/facebookIcon'
import TrialCTA from "../components/trialCta";
import merchantOfferView from '../../public/Merchant-Offer-View.svg'
import { Helmet } from 'react-helmet'

export const query = graphql`
    query ($slug: String!) {
        site {
            siteMetadata {
              blog {
                title
                description
              }
            }
          }
        ghostAuthor (slug: {eq: $slug}) {
            id
            name
            profile_image
            cover_image
            bio
            website
            location
            facebook
            slug
            twitter
            meta_title
            meta_description
            postCount
        }
    }
    
`

const BlogAuthor = (props) => {
    return (
        <>
        <Helmet>
        {/* Meta */}
          <html lang="en" />
          <title>{props.data.ghostAuthor.name} — {props.data.site.siteMetadata.blog.title}</title>
          <meta charSet="utf-8" />
          <meta name="title" content={`${props.data.ghostAuthor.name} — ${props.data.site.siteMetadata.blog.title}`}/>
          <meta name="description" content={`${props.data.ghostAuthor.name} is an author for the Batch ecommerce marketing blog, sharing tips, tricks and guides to boost your Shopify sales.`} /> 
          <link rel="canonical" href={props.data.ghostAuthor.slug} />
      </Helmet>
        <Layout>
            <section className="overflow-hidden bg-white">
                <div className="relative max-w-screen-xl px-4 pt-20 pb-12 mx-auto sm:px-6 lg:px-8 lg:py-20">
                    <svg className="absolute left-0 transform -translate-y-24 top-full translate-x-80 lg:hidden" width="784" height="404" fill="none" viewBox="0 0 784 404">
                    <defs>
                        <pattern id="e56e3f81-d9c1-4b83-a3ba-0d0ac8c32f32" x="0" y="0" width="20" height="20" patternUnits="userSpaceOnUse">
                        <rect x="0" y="0" width="4" height="4" className="text-gray-200" fill="currentColor" />
                        </pattern>
                    </defs>
                    <rect width="784" height="404" fill="url(#e56e3f81-d9c1-4b83-a3ba-0d0ac8c32f32)" />
                    </svg>

                    <svg className="absolute hidden transform translate-x-1/2 -translate-y-1/2 lg:block right-full top-1/2" width="404" height="784" fill="none" viewBox="0 0 404 784">
                    <defs>
                        <pattern id="56409614-3d62-4985-9a10-7ca758a8f4f0" x="0" y="0" width="20" height="20" patternUnits="userSpaceOnUse">
                        <rect x="0" y="0" width="4" height="4" className="text-gray-200" fill="currentColor" />
                        </pattern>
                    </defs>
                    <rect width="404" height="784" fill="url(#56409614-3d62-4985-9a10-7ca758a8f4f0)" />
                    </svg>

                    <div className="relative lg:flex lg:items-center">
                    <div className="hidden lg:block lg:flex-shrink-0">
                        <img className="w-48 h-48 rounded-full xl:h-64 xl:w-64" src={props.data.ghostAuthor.profile_image} alt={`${props.data.ghostAuthor.name}`} />
                    </div>

                    <div className="relative lg:ml-10">
                        <svg className="absolute top-0 left-0 text-indigo-200 transform -translate-x-8 -translate-y-24 opacity-50 h-36 w-36" stroke="currentColor" fill="none" viewBox="0 0 144 144">
                        <path stroke-width="2" d="M41.485 15C17.753 31.753 1 59.208 1 89.455c0 24.664 14.891 39.09 32.109 39.09 16.287 0 28.386-13.03 28.386-28.387 0-15.356-10.703-26.524-24.663-26.524-2.792 0-6.515.465-7.446.93 2.327-15.821 17.218-34.435 32.11-43.742L41.485 15zm80.04 0c-23.268 16.753-40.02 44.208-40.02 74.455 0 24.664 14.891 39.09 32.109 39.09 15.822 0 28.386-13.03 28.386-28.387 0-15.356-11.168-26.524-25.129-26.524-2.792 0-6.049.465-6.98.93 2.327-15.821 16.753-34.435 31.644-43.742L121.525 15z" />
                        </svg>
                        <blockquote className="relative">
                        <div className="text-2xl font-medium leading-9 text-gray-900">
                            <p>
                            {props.data.ghostAuthor.bio}
                            </p>
                        </div>
                        <footer className="mt-8">
                            <div className="flex">
                                <div className="flex-shrink-0 lg:hidden">
                                    <img className="w-12 h-12 rounded-full" src={props.data.ghostAuthor.profile_image} alt={`${props.data.ghostAuthor.name}`} />
                                </div>
                                <div className="ml-4 lg:ml-0">
                                    <h1 className="text-xl font-medium leading-6 text-gray-900">{props.data.ghostAuthor.name}</h1>
                                    <div className="text-base font-medium leading-6 text-gray-600">{props.data.ghostAuthor.location}</div>
                                    <div className="text-base font-medium leading-6 text-indigo-600">
                                        <Link href={props.data.ghostAuthor.website} rel="nofollow">{props.data.ghostAuthor.website}</Link>
                                    </div>
                                    <div className="inline-flex mt-4 font-medium">
                                        { 
                                            props.data.ghostAuthor.facebook ?
                                                <div className="flex">
                                                    <FacebookIcon props={props.data.ghostAuthor} />
                                                </div>
                                            : 
                                                "" 
                                        }

                                        { 
                                            props.data.ghostAuthor.twitter ? 
                                                <div className="flex">
                                                    <TwitterIcon props={props.data.ghostAuthor} />
                                                </div>
                                            : 
                                                "" 
                                        }
                                    </div> 
                                </div>
                            </div>
                        </footer>
                        </blockquote>
                    </div>
                    </div>
                </div>
            </section>
        <TrialCTA 
            bgClass={"bg-indigo-600"}
            title={"Want to give it a try? It's a free install."} 
            description={"Collect an unlimited number of offers and activate them for immediate revenue on the Forever Free plan."}
            buttonText={"Install Batch for free"} 
            url={"https://apps.shopify.com/batch-offers"} 
            image={merchantOfferView}
        />
        </Layout>
        </>
    )
}

export default BlogAuthor